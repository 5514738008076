<template>
    <section class="section6 row py-5">

        <div class="col-12 flex-center text-center px-2 pt-5 pb-3">
            <div style="max-width: 1200px">
                <span class="to--title-sm">
                    Miércoles 3 de Mayo, 6:00PM Pacífico
                </span>    
            </div>
        </div>


        <div class="col-12 flex-center px-0">
            <div class="bg-primary w-100 position-absolute" style="height: 10px; z-index: 0">
                
            </div>
            <button class="btn btn-warning my-3 to--title-md text-white px-5"
                style="z-index: 1;">
                    Inscríbete YA !
            </button>
        </div>

        <div class="col-12 flex-center text-center px-2">
            <div style="max-width: 1200px">
                <span class="to--description-md text-warning">
                    Hemos ayudado a muchas personas a salir del hoyo financiero y estos son algunos testimonios de los que ellos dicen del programa.
                </span>    
            </div>
        </div>
    </section>
</template>

<script>
    // Images

    export default {
        name: 's6',
        data() {
            return {

            }
        }
    }
</script>

<style lang="scss" scoped>
    
</style>