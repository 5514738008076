<template>
    <section class="section2 row bg-custom py-3 px-2 px-xl-4"
        :style="{ 'background-image': `url(${bg_blue})`, 'height': 'auto' }">

        <div class="col-12 col-md-6 order-1 order-md-0">
            <div class="w-100 flex-center">
                <img :src="image_s2" class="img-fluid">    
            </div>
            
            <div class="w-100 flex-center">
                <button class="btn btn-warning w-100 my-3 to--title-md text-white"
                    style="max-width: 696px; padding: 5px 0px 7px;">
                        Inscríbete YA!
                </button>
            </div>
        </div>

        <div class="col-12 col-md-6 flex-center order-0 order-md-1" 
            style="min-height: 300px;">

            <div class="text-center" 
                style="max-width: 617px;">

                <div class="mb-2">
                    <span class="to--title-sm text-warning">
                        Cupo Estrictamente Limitado    
                    </span>    
                </div>

                <div class="mb-4">
                    <span class="to--description-md text-white">
                        Aunque es una clase gratis tenemos cupo estrictamente limitado y los primeros
                        en reservar ocuparán su lugar, así que no pierdas más tiempo ni dinero.
                    </span>    
                </div>
                
                <div class="mb-3">
                    <span class="to--title-sm text-warning playfair-bold">
                        Clase Gratis de Inversiones y Manejo de Capital en vivo con Tony Almazán.   
                    </span>
                </div>

                <div class="mb-2">
                    <span class="to--description-md text-warning">
                        Miércoles 3 de Mayo, <br>
                        6:00PM Pacífico.    
                    </span>
                </div>
               
            </div>
        </div>
    </section>
</template>

<script>
    // Images
    import bg_blue from 'ASSETS/images/bg/bg_blue'
    import image_s2 from 'ASSETS/images/s2/image'

    export default {
        name: 's2',
        data() {
            return {
                bg_blue,
                image_s2
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>