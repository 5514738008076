<template>
    <section class="section8 row bg-warning shadow mt-5 py-3 px-2 px-xl-4">
        <div class="col-12 text-center text-white">
            
            <div class="flex-center">
                <div class="bg-info w-100 mt-4 mb-5" 
                    style="max-width: 1250px; height: 22px; border-radius: 50px 0px;">
                </div>
            </div>
            
            <div class="flex-center">
                <div style="max-width: 1400px;">
                    <div class="mb-3">
                        <span class="to--description-lg">
                            No pierdas más tiempo ni dinero, aprende a invertir y manejar tus finanzas y cambia tu realidad financiera como otros ya lo han hecho, inscríbete a nuestra clase de 
                        </span>    
                    </div>
                    <span class="to--title-sm playfair-semibold">
                        Inversiones y Manejo de Capital que es totalmente gratis y empieza hoy mismo.
                    </span>
                </div>
            </div>

            <div class="flex-center">
                <div class="bg-info w-100 mt-5 mb-4" 
                    style="max-width: 1250px; height: 22px; border-radius: 50px 0px;">
                </div>
            </div>

        </div>
    </section>
</template>

<script>
    // Images

    export default {
        name: 's8',
        data() {
            return {

            }
        }
    }
</script>

<style lang="scss" scoped>

</style>