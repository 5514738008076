var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section7 row bg-custom flex-center py-5",style:({ 'background-image': `url(${_vm.bg_blue})`, 'height': 'auto' })},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-7 text-right py-5"},[_c('span',{staticClass:"to--description-sm text-warning"},[_vm._v(" Antes yo pensaba que las inversiones eran solo para ricos o gente con mucha educación, pero hoy después de haber aprendido con el programa de Tony se que realmente cualquiera que quiera lo puede hacer. "),_c('br'),_vm._v(" Francisco M. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-7 text-left py-5"},[_c('span',{staticClass:"to--description-sm text-white"},[_vm._v(" Antes de conocer a Tony mis finanzas eran un caos, a pesar de trabajar mucho y ganar dinero no pasaba de lo mismo, pero después de aprender el sistema que Tony enseña como por arte de magia mis finanzas se transformaron por que con él aprendí que el dinero ya lo tenia, solo que no sabía cómo manejarlo e invertirlo. "),_c('br'),_vm._v(" Ramon O. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-7 text-right py-5"},[_c('span',{staticClass:"to--description-sm text-warning"},[_vm._v(" No encuentro palabras para agradecer a Tony y a su maravilloso equipo por la labor que hacen con nuestra comunidad, ya que no solo aprendí a manejar y mejorar mis finanzas a través de las inversiones, si no que recupere mis sueños y ahora sé que son más posibles que nunca. "),_c('br'),_vm._v(" Juan Carlos ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-7 text-left py-5"},[_c('span',{staticClass:"to--description-sm text-white"},[_vm._v(" Una sola palabra MARAVILLOSO, este programa no solo me a devuelto la vida y esperanza sino que ahora veo que no solo voy a heredar dinero a mis hijos sino que les heredare el conocimiento de cómo manejarlo, ya que esto fue lo que aprendí con Tony que el conocimiento especializado es toda la diferencia. "),_c('br'),_vm._v(" Julia P. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-7 text-right py-5"},[_c('span',{staticClass:"to--description-sm text-warning"},[_vm._v(" Aqui aprendi que para tener dinero hay que aprender sobre dinero, yo era de los que pensaba que sabía mucho pero como no era conocimiento especializado no avanzaba en nada, ahora a pesar de que aun no llego a mi meta cuento con la paz y satisfacción de que mis finanzas están en control y mi dinero cada vez crece más. "),_c('br'),_vm._v(" Manuel M. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-7 text-left py-5"},[_c('span',{staticClass:"to--description-sm text-white"},[_vm._v(" Pensé que para invertir se ocupaba mucho conocimiento pero cuando asistí con Tony él me enseñó con su manera practica que es mas fácil de lo que nunca pensé, y quiero invitar a todas las personas que conozco a que se den la oportunidad por que es muy fácil y cualquiera lo puede hacer. "),_c('br'),_vm._v(" Joaquin C. ")])])
}]

export { render, staticRenderFns }