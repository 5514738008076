<template>
    <section class="section7 row bg-custom flex-center py-5"
        :style="{ 'background-image': `url(${bg_blue})`, 'height': 'auto' }">

        <div class="col-12 col-md-7 text-right py-5">
            <span class="to--description-sm text-warning">
                Antes yo pensaba que las inversiones eran solo
                para ricos o gente con mucha educación,
                pero hoy después de haber aprendido con
                el programa de Tony se que realmente cualquiera
                que quiera lo puede hacer.
                <br>
                Francisco M.
            </span>
        </div>

        <div class="col-12 col-md-7 text-left py-5">
            <span class="to--description-sm text-white">
                Antes de conocer a Tony mis finanzas eran un caos, a pesar de trabajar mucho y ganar dinero no pasaba  de lo mismo, pero después de aprender el sistema que  Tony enseña como por arte de magia mis finanzas se transformaron por que con él aprendí que el dinero ya lo tenia, solo que no sabía cómo manejarlo e invertirlo.
                <br>
                Ramon O.
            </span>
        </div>


        <div class="col-12 col-md-7 text-right py-5">
            <span class="to--description-sm text-warning">
                No encuentro palabras para agradecer a Tony y a su maravilloso equipo por la labor que hacen con nuestra comunidad, ya que no solo aprendí a manejar y mejorar mis finanzas a través de las inversiones, si no que recupere mis sueños y ahora sé que son más posibles que nunca.
                <br>
                Juan Carlos
            </span>
        </div>

        <div class="col-12 col-md-7 text-left py-5">
            <span class="to--description-sm text-white">
                Una sola palabra MARAVILLOSO, este programa no solo me a devuelto la vida y esperanza sino que ahora veo que no solo voy a heredar dinero a mis hijos sino que les heredare el conocimiento de cómo manejarlo, ya que esto fue lo que aprendí con Tony que el conocimiento especializado es toda la diferencia.
                <br>
                Julia P.
            </span>
        </div>

        <div class="col-12 col-md-7 text-right py-5">
            <span class="to--description-sm text-warning">
                Aqui aprendi que para tener dinero hay que aprender sobre dinero, yo era de los que pensaba que sabía mucho pero como no era conocimiento especializado no avanzaba en nada, ahora a pesar de que aun no llego a mi meta cuento con la paz y satisfacción de que mis finanzas están en control y mi dinero cada vez crece más.
                <br>
                Manuel M.
            </span>
        </div>

        <div class="col-12 col-md-7 text-left py-5">
            <span class="to--description-sm text-white">
                Pensé que para invertir se ocupaba mucho conocimiento pero cuando asistí con Tony él me enseñó con su manera practica que es mas fácil de lo que nunca pensé,  y quiero invitar a todas las personas que conozco a que se den la oportunidad por que es muy fácil y cualquiera lo puede hacer.
                <br>
                Joaquin C.
            </span>
        </div>
        
    </section>
</template>

<script>
    // Images
    import bg_blue from 'ASSETS/images/bg/bg_blue'
    import image1 from 'ASSETS/images/s7/image1'
    import image2 from 'ASSETS/images/s7/image2'
    import image3 from 'ASSETS/images/s7/image3'
    import image4 from 'ASSETS/images/s7/image4'
    import image5 from 'ASSETS/images/s7/image5'
    import image6 from 'ASSETS/images/s7/image6'

    export default {
        name: 's7',
        data() {
            return {
                bg_blue,
                image1,
                image2,
                image3,
                image4,
                image5,
                image6
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/minxin.scss';

    .text-centerleft {
        text-align: center;

        @include upPixels(767px) {
            text-align: left;
        }
    }

    .text-centerright {
        text-align: center;

        @include upPixels(767px) {
            text-align: right;
        }
    }
</style>