<template>
    <section class="section1 row bg-custom px-2 px-xl-4"
        :style="{ 'background-image': `url(${bg_green})`, 'height': 'auto' }">
        <div class="col-12 col-md-7 col-lg-9 flex-start-center py-2" style="min-height: 400px;">
            <div>
                <span class="to--title-xl text-info">
                    BIENVENIDO
                </span> <br>

                <div class="my-2">
                    <span class="to--description-lg text-white">
                        Estás a punto de iniciar tu camino a la Transformación Financiera y vamos a empezar con esta poderosa clase llamada
                    </span>
                </div>
                <div>
                    <span class="to--title-sm playfair-bold text-white">
                        “CLASE DE INVERSIONES Y MANEJO DE CAPITAL”
                    </span>
                </div>
                
            </div>
        </div>

        <div class="col-12 col-md-5 col-lg-3 height-custom">
            <img :src="icon_tony_foto" 
                class="position-absolute" 
                style="right: 0px; bottom: 0px">
        </div>
    </section>
</template>

<script>
    // Images
    import bg_green from 'ASSETS/images/bg/bg_green'
    import icon_tony_foto from 'ASSETS/images/tonyfoto'

    export default {
        name: 's1',
        data() {
            return {
                bg_green,
                icon_tony_foto
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/minxin.scss';

    .height-custom {
        @include downPixels(767px) {
            min-height: 280px;
        }
    }

</style>