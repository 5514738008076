<template>
    <div id="app">
        <to-navbar></to-navbar>

        <router-view>
            
        </router-view>

        <to-footer></to-footer>
    </div>
</template>

<script>
    // Components
    import Navbar from "../src/components/Navbar.vue";
    import Footer from "../src/components/Footer.vue";

    export default {
        name: 'app',
        components: {
            'to-navbar': Navbar,
            'to-footer': Footer,
        }
    }
</script>

<style lang="scss">
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
    }
</style>
