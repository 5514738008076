<template>
    <section class="section9 row">
        <div class="col-12 px-0">
            <img :src="photo2" class="img-fluid">
        </div>
    </section>
</template>

<script>
    // Images
    import photo2 from 'ASSETS/images/s10/photo2'

    export default {
        name: 's10',
        data() {
            return {
                photo2
            }
        }
    }
</script>

<style lang="scss" scoped>
    
</style>