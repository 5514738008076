<template>
    <section class="section6 row py-5">

        <div class="col-12 flex-center text-center px-2 my-4">
            <div style="max-width: 1100px">
                <span class="to--title-md playfair-bold text-warning">
                    Clase Gratis de Inversiones y Manejo de Capital en vivo con Tony Almazán.
                </span>
            </div>
        </div>

        <div class="col-12 flex-center text-center px-2 mb-4">
            <span class="to--title-sm">
                Miércoles 3 de Mayo, 6:00PM Pacífico
            </span>
        </div>

        <div class="col-12 flex-center px-0">
            <div class="bg-info w-100 position-absolute" style="height: 10px; z-index: 0">
                
            </div>
            <button class="btn btn-primary flex-center my-3 px-5"
                style="z-index: 1;">
                    <span class="to--title-md text-white">
                        Inscríbete YA!
                    </span>
            </button>
        </div>

        <div class="col-12 flex-center text-center px-2">
            <div style="max-width: 700px">
                <span class="to--description-md text-warning">
                    Si tienes más preguntas o necesitas ayuda ponte
                    en contacto con uno de nuestros representantes.
                </span>    
            </div>
        </div>

        <div class="col-12 flex-center text-center px-2 my-4">
            <span class="to--title-md">
                (323) 770 9683
            </span>
        </div>

        <div class="col-12 flex-center text-center px-2">
            <span class="to--description-md text-warning">
                Horario de Lunes a Viernes de 9:00AM a 5:00PM hora del Pacífico.
            </span>
        </div>
        
    </section>
</template>

<script>
    // Images

    export default {
        name: 's6',
        data() {
            return {

            }
        }
    }
</script>

<style lang="scss" scoped>
    
</style>