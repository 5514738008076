<template>
    <section class="row header">
        <div class="col-12 px-0">
            <div class="flex-start-center bg-dark px-1 px-sm-3 px-lg-5 py-2">
                <div class="mx-3" style="width: 24px;">
                    <img :src="icon_fb" class="img-fluid">
                </div>
                <div class="mx-3" style="width: 24px;">
                    <img :src="icon_yt" class="img-fluid">
                </div>
                <div class="mx-3" style="width: 24px;">
                    <img :src="icon_ig" class="img-fluid">
                </div>
            </div>
            <div class="flex-between-center bg-success px-1 px-sm-3 px-lg-5 py-3">
                <img :src="icon_tony" class="mx-3">
                <!-- <span class="text-white">
                    INGRESAR AL CURSO
                </span> -->
                
            </div>    
        </div>
    </section>
</template>

<script>
    // Images
    import icon_fb from 'ASSETS/images/fb'
    import icon_yt from 'ASSETS/images/yt'
    import icon_ig from 'ASSETS/images/ig'
    import icon_tony from 'ASSETS/images/tony'

    export default {
        name: 'Navbar',
        components: {
            
        },
        data() {
            return {
                icon_fb,
                icon_yt,
                icon_ig,
                icon_tony
            }
        },
        methods: {
            redirectToSamePage(name_route, element_id) {
                //console.log(document.getElementById(element_id), 'redirectToSamePage')
                this.redirect(name_route)
                
                setTimeout(function() {
                    const yOffset = -1 * ($('#al_navbar').height() + 32); 
                    const element = document.getElementById(element_id);
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                    window.scrollTo({top: y, behavior: 'smooth'});

                }, 300);
            },
        },
        created() {
            
        }
    }
</script>

<style lang="scss" scoped>

</style>