<template>
    <footer class="to-footer flex-center">
        <div class="w-100 mw-footer">

            <div class="row flex-between-start pb-5 mb-4" 
            style="border-bottom: 1px solid #687068;">

                <div class="col-lg-12 col-xl-3 mb-5">
                    <img :src="icon_tony" class="mb-5">

                    <div class="flex-start">
                        <div class="mr-2" style="width: 20px;">
                            <img :src="icon_fb" class="img-fluid">
                        </div>
                        <div class="mx-2" style="width: 20px;">
                            <img :src="icon_yt" class="img-fluid">
                        </div>
                        <div class="mx-2" style="width: 20px;">
                            <img :src="icon_ig" class="img-fluid">
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3 col-xl-2 mb-4 mb-lg-0">
                    <div class="title">
                        <span class=" playfair-bold text-white">
                            Contacto
                        </span>
                    </div>

                    <div class="mt-3 mb-2">
                        <span class="text-secondary">
                            Dirección:
                        </span> <br>
                        <span class="text-white">
                            3251 w 6th st ste 400A Los Angeles, CA 90020
                        </span>
                    </div>
                     
                    <div class="my-2">
                        <span class="text-secondary">
                            Celular:
                        </span> <br>
                        <span class="text-white">
                            (323) 770 9683
                        </span>
                    </div>

                    <div class="my-2">
                        <span class="text-secondary">
                            E-mail:
                        </span> <br>
                        <span class="text-white">
                            contacto@tonyalmazan.com
                        </span>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3 col-xl-2 mb-4 mb-lg-0">
                    <div class="title">
                        <span class="playfair-bold text-white">
                            Información
                        </span>
                    </div>

                    <div class="mt-3 mb-2">
                        <span class="text-white">
                            F.A.Q.
                        </span>
                    </div>

                    <div class="my-2">
                        <span class="text-white">
                            Términos y condiciones
                        </span>
                    </div>

                    <div class="my-2">
                        <span class="text-white">
                            Política de privacidad
                        </span>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3 col-xl-2 mb-4 mb-lg-0">
                    <div class="title">
                        <span class="playfair-bold text-white">
                            Links Rapidos
                        </span>
                    </div>

                    <div class="mt-3 mb-2">
                        <span class="text-white">
                            Video Curso
                        </span>
                    </div>

                    <div class="my-2">
                        <span class="text-white">
                            Contáctanos
                        </span>
                    </div>
                </div>
            </div>

            <div class="w-100 text-center">
                <span class="text-white">
                    Copyright © 2022 © Éxito Financiero, LLC. Todos los derechos reservados.    
                </span>
            </div>
        </div>
        
    </footer>
</template>

<script>
    // Images
    import icon_tony from 'ASSETS/images/tony'
    import icon_fb from 'ASSETS/images/fb'
    import icon_yt from 'ASSETS/images/yt'
    import icon_ig from 'ASSETS/images/ig'

    export default {
        name: 'Footer',
        data() {
            return {
                icon_tony,
                icon_fb,
                icon_yt,
                icon_ig
            }
        },
        methods: {
            redirect(name_route) {
                if(this.$route.name !== name_route) {
                    window.scrollTo(0, 0)

                    this.$router.push({
                        name: name_route
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../assets/scss/minxin.scss';

    .to-footer {
        background-color: #182323;
        padding-top: 100px;
        padding-bottom: 80px;

        font-family: Arial;
        font-size: 14px;
        font-weight: 500;


        .title {
            font-size: 20px;
            border-bottom: 1px solid white;
            padding-bottom: 10px;
        }

        .mw-footer {
            max-width: 300px;

            @include upPixels(460px) {
                max-width: 400px;
            }
            @include upPixels(576px) {
                max-width: 520px;
            }
            @include upPixels(768px) {
                max-width: 600px;
            }
            @include upPixels(992px) {
                max-width: 820px;
            }
            @include upPixels(1200px) {
                max-width: 1170px;
            }
        }
    }
</style>