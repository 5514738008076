<template>
    <div class="home">
        <!-- <div>
            <span class="to--title-xl">
                Hola
            </span><br>
            <span class="to--title-lg">
                Hola
            </span><br>
            <span class="to--title-md">
                Hola
            </span><br>
            <span class="to--title-sm">
                Hola
            </span><br><br>

            <span class="to--description-md">
                Hola
            </span><br>
            <span class="to--description-sm">
                Hola
            </span>
        </div> <br><br><br> -->

        <to-section-1></to-section-1>
        <to-section-2></to-section-2>
        <to-section-3></to-section-3>
        <to-section-4></to-section-4>
        <to-section-5></to-section-5>
        <to-section-6></to-section-6>
        <to-section-7></to-section-7>
        <to-section-8></to-section-8>
        <to-section-9></to-section-9>
    </div>
</template>

<script>
    // Components
    import section1 from 'COMPONENTS/sections/Section1'
    import section2 from 'COMPONENTS/sections/Section2'
    import section3 from 'COMPONENTS/sections/Section3'
    import section4 from 'COMPONENTS/sections/Section4'
    import section5 from 'COMPONENTS/sections/Section5'
    import section6 from 'COMPONENTS/sections/Section6'
    import section7 from 'COMPONENTS/sections/Section7'
    import section8 from 'COMPONENTS/sections/Section8'
    import section9 from 'COMPONENTS/sections/Section9'

    export default {
        name: 'Home',
        components: {
            'to-section-1': section1,
            'to-section-2': section2,
            'to-section-3': section3,
            'to-section-4': section4,
            'to-section-5': section5,
            'to-section-6': section6,
            'to-section-7': section7,
            'to-section-8': section8,
            'to-section-9': section9,
        }
    }
</script>

<style>
    @import '../assets/scss/minxin.scss';

</style>
