<template>
    <section class="section3 row bg-custom shadow px-2 px-xl-4"
        :style="{ 'background-image': `url(${bg_white})`, 'height': 'auto' }">
            <div class="col-10 py-md-5 px-2 px-lg-5">

                <div class="pl-2 pl-xl-5">
                    <span class="to--title-md playfair-semibold text-warning">
                        En esta clase aprenderás:
                    </span>
                </div>

                <ul>

                    <li class="to--description-md my-4 py-2">
                        Cómo lograr tus metas y dar en el blanco financiero.
                    </li>

                    <li class="to--description-md text-warning my-4 py-2">
                        Una manera infalible para ganar más dinero.
                    </li>

                    <li class="to--description-md my-4 py-2">
                        Aprenderás a estar en la cúspide de la pirámide económica sin importar tu
                        nivel socioeconómico o educativo.
                    </li>

                    <li class="to--description-md text-warning my-4 py-2">
                        Aprenderemos y revisaremos el proceso de manejo de dinero en 4 sencillos
                        pasos ya que la gran mayoría solo sabe ganar y gastar.
                    </li>

                    <li class="to--description-md my-4 py-2">
                        Compartiré contigo un tripié financiero que mantenga tus finanzas sólidas como la roca.
                    </li>

                    <li class="to--description-md text-warning my-4 py-2">
                        Ejemplificaré por qué ganar un millón de dólares, hoy es más fácil que nunca y te daré ejemplos de cómo tú también puedes lograrlo.
                    </li>

                    <li class="to--description-md my-4 py-2">
                        Te pondré un ejemplo super práctico de cómo una persona con conocimiento de inversión y finanzas puede comprar una casa sin que esta se convierta en una pesadilla.
                    </li>

                    <li class="to--description-md text-warning my-4 py-2">
                        Al final recibirás la oportunidad de empezar tu transformación financiera guiados y llevados de la mano para asegurarnos que des en el blanco financiero.
                    </li>

                </ul>

            </div>
    </section>
</template>

<script>
    // Images
    import bg_white from 'ASSETS/images/bg/bg_white2'

    export default {
        name: 's3',
        data() {
            return {
                bg_white
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/minxin.scss';

    .height-custom {
        @include downPixels(767px) {
            min-height: 480px;
        }
    }
</style>