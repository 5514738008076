<template>
    <section class="section9 row">
        <div class="col-12 px-0">
            <img :src="photo1" class="img-fluid">
            <img :src="photo4" class="img-fluid">
        </div>
    </section>
</template>

<script>
    // Images
    import photo1 from 'ASSETS/images/s10/photo1'
    import photo4 from 'ASSETS/images/s10/photo4'

    export default {
        name: 's10',
        data() {
            return {
                photo1,
                photo4
            }
        }
    }
</script>

<style lang="scss" scoped>
    
</style>